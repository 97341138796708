<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-container">
      <button class="close-btn" @click="show = false">&times;</button>
      <!-- Header -->
      <div class="modal-header">
        <img
          class="modal-logo"
          src="@/assets/publicDonationPage/parentSignUpModalLogo.png"
          alt="logo"
        />
        <h3>{{ modalActionText.title }}</h3>
        
      </div>

      <!-- Sign Up Form -->
      <form
        v-if="signUpPageFlag"
        @submit.prevent="onClickSignUpHandler"
        class="modal-form"
      >
        <label>Name</label>
        <input type="text" v-model="signUp.name" />
        <span v-if="errors.signUp.name" class="error">
          {{ errors.signUp.name }}
        </span>

        <label>Email</label>
        <input type="email" v-model="signUp.email" />
        <span v-if="errors.signUp.email" class="error">
          {{ errors.signUp.email }}
        </span>

        <label>Password</label>
        <input type="password" v-model="signUp.password" />
        <span v-if="errors.signUp.password" class="error">
          {{ errors.signUp.password }}
        </span>

        <button type="button" @click="openGoogleLoginPopup" class="google-btn">
          <img src="https://img.icons8.com/color/16/000000/google-logo.png" />
          Sign in with Google
        </button>
        <button type="submit" class="action-btn">
          SIGN UP
        </button>
      </form>

      <!-- Sign In Form -->
      <form
        v-else
        @submit.prevent="onClickSignInHandler"
        class="modal-form"
      >
        <label>Email</label>
        <input type="email" v-model="login.email" />
        <span v-if="errors.login.email" class="error">
          {{ errors.login.email }}
        </span>

        <label>Password</label>
        <input type="password" v-model="login.password" />
        <span v-if="errors.login.password" class="error">
          {{ errors.login.password }}
        </span>

        <button type="submit" class="action-btn">
          SIGN IN
        </button>

        <button type="button" @click="openGoogleLoginPopup" class="google-btn">
          <img src="https://img.icons8.com/color/16/000000/google-logo.png" />
          Sign in with Google
        </button>

        <p class="forgot-password">
          Forgot password?
          <a href="#" @click="toggleForgotPasswordModal">Reset</a>
        </p>
      </form>

      <!-- Footer Toggle -->
      <div class="modal-footer">
        {{ modalActionText.actionSub }}
        <button @click="toggleForm()" class="link-btn">
          {{ modalActionText.actionBtn }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import authToken from "@/common/authToken";
import RULES from "@/common/fieldRules";
import { API_MASTER_SOCIAL_LOGIN } from "@/constants/APIUrls";
import { API_COMMON_URL, ROUTER_URL } from "@/constants/urls";
import Keys from "@/constants/keys";

export default {
  name: "ParentSignUpModal",
  data() {
    return {
      signUpPageFlag: false,
      modalActionText: {
        title: "Welcome back, Parent",
        actionSub: "Dont have an account? ",
        actionBtn: "SignUp",
      },
      rules: RULES,
      signUp: {
        loading: false,
        name: "",
        email: "",
        password: "",
      },
      login: {
        loading: false,
        email: "",
        password: "",
      },
      errors: {
        signUp: {},
        login: {},
      },
    };
  },
  computed: {
    // Getters from Vuex
    ...mapGetters({
      showGetter: "publicDonationPage/getParentSignUpModalShow",
      getterSignUpPageFlag: "publicDonationPage/getSignUpPageFlag",
    }),

    // v-if in template uses this for modal show/hide
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        // If we hide the modal, clear data
        if (!value) this.clearModal();
        else {
          // Ensure correct form is displayed
          this.signUpPageFlag = this.getterSignUpPageFlag;
        }
        // Dispatch Vuex action to toggle the modal
        return this.toggleModal({ show: value });
      },
    },
  },
  watch: {
    getterSignUpPageFlag(value) {
      this.signUpPageFlag = value;
      if (this.signUpPageFlag) {
        this.modalActionText.title = "Parent Sign up";
        this.modalActionText.actionSub = "Already have an account? ";
        this.modalActionText.actionBtn = "Login";
      } else {
        this.modalActionText.title = "Welcome back, Parent";
        this.modalActionText.actionSub = "Dont have an account? ";
        this.modalActionText.actionBtn = "Signup";
      }
    },
  },
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleParentSignUpModal",
      updateUserDetails: "publicDonationPage/updateUserDetails",
      toggleParentVerifyEmailModal:
        "publicDonationPage/toggleParentVerifyEmailModal",
      toggleResetPasswordModal: "publicDonationPage/toggleResetPasswordModal",
      showToast: "snackBar/showToast",
    }),

    // ==========  VALIDATION  ==========
    validateSignUpForm() {
      this.errors.signUp = {};
      if (!this.signUp.name) {
        this.errors.signUp.name = "Name is required";
      }
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.signUp.email || !emailRegex.test(this.signUp.email)) {
        this.errors.signUp.email = "Valid email is required";
      }
      if (!this.signUp.password || this.signUp.password.length < 6) {
        this.errors.signUp.password = "Password must be at least 6 characters";
      }
      return Object.keys(this.errors.signUp).length === 0;
    },

    validateLoginForm() {
      this.errors.login = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.login.email || !emailRegex.test(this.login.email)) {
        this.errors.login.email = "Valid email is required";
      }
      if (!this.login.password) {
        this.errors.login.password = "Password is required";
      }
      return Object.keys(this.errors.login).length === 0;
    },

    // ==========  SIGN-IN / SIGN-UP HANDLERS  ==========
    onClickSignInHandler() {
      // Fix: use validateLoginForm() for sign in
      if (this.validateLoginForm()) {
        this.login.loading = true;

        const successHandler = (res) => {
          try {
            localStorage.setItem("token", res.headers.token);
          } catch (e) {
            console.log("LocalStorage error:", e);
          }
          const userDetails = authToken.decodedToken();
          if (userDetails.user_type === "parent") {
            if (authToken.loginForFirstTime()) {
              this.updateUserDetails({ user: userDetails });
              this.showToast({ message: "Logged In", color: "s" });
              this.$router.push({
                name: ROUTER_URL.publicParentDashboard.name,
              });
              this.show = false;
            }
          } else {
            // Non-Parent user => show error, remove token
            localStorage.removeItem("token");
            this.showToast({
              message: "Access Denied: Only parents can log in",
              color: "e",
            });
          }
          this.login.loading = false;
        };

        const failureHandler = (res) => {
          if (res.data?.is_verification_done === false) {
            this.showToast({ message: res.data.message, color: "e" });
            // Could open email verification if needed
          }
          this.login.loading = false;
        };

        const formData = {
          [Keys.USER_EMAIL]: this.login.email.trim(),
          [Keys.PASSWORD]: this.login.password,
          device_type: "web",
        };

        Axios.request_POST(
          API_COMMON_URL.login,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          false
        );
      }
    },

    onClickSignUpHandler() {
      if (this.validateSignUpForm()) {
        this.signUp.loading = true;

        const successHandler = (res) => {
          console.log(res);
          this.toggleModal({ show: false });
          this.updateUserDetails({ user: authToken.decodedToken() });
          this.showToast({ message: "Logged In", color: "s" });
          this.$router.push({
            name: ROUTER_URL.publicParentDashboard.name,
          });
          this.signUp.loading = false;
        };

        const failureHandler = (res) => {
          console.log("Sign up failure:", res);
          this.signUp.loading = false;
        };

        let formData = {
          user_type: "Parent",
          user_name: this.signUp.name,
          [Keys.USER_EMAIL]: this.signUp.email.trim(),
          [Keys.PASSWORD]: this.signUp.password,
          device_type: "web",
        };

        Axios.request_POST(
          API_COMMON_URL.createUser,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          false
        );
      }
    },

    // ==========  GOOGLE LOGIN HANDLERS  ==========
    openGoogleLoginPopup() {
      const popupUrl = "https://devharlemwizardsinabox.com/google-popup";
      const width = 500;
      const height = 600;
      const left = window.screen.width / 2 - width / 2;
      const top = window.screen.height / 2 - height / 2;

      window.open(
        popupUrl,
        "GoogleLogin",
        `width=${width},height=${height},top=${top},left=${left},resizable=no,scrollbars=no,status=no`
      );
      // Rebind event listener for a new popup
      window.removeEventListener("message", this.handleGoogleLoginMessage);
      window.addEventListener("message", this.handleGoogleLoginMessage, false);
    },

    handleGoogleLoginMessage(event) {
      // Make sure we trust the origin
      const trustedOrigin = "https://devharlemwizardsinabox.com";
      if (event.origin !== trustedOrigin) return;

      const { token, source } = event.data;
      if (source === "google-login" && token) {
        this.loginApiCall(token);
        this.show = false;
      }
      window.removeEventListener("message", this.handleGoogleLoginMessage);
    },

    loginApiCall(idToken) {
      const formData = {
        social_token: idToken,
        social_provider: "google",
        device_type: "web",
      };
      Axios.request_POST(
        API_MASTER_SOCIAL_LOGIN,
        formData,
        {},
        this.googleSuccessHandler,
        this.googleFailureHandler,
        false,
        false
      );
    },

    googleSuccessHandler(res) {
      const idToken = res.headers.token;
      try {
        localStorage.setItem("token", idToken);
      } catch (e) {
        console.error("LocalStorage error:", e);
      }
      const userDetails = authToken.decodedTokenFromString(idToken);
      // Check if user is a parent
      if (userDetails.user_type === "parent") {
        // update user & route to parent dashboard
        const updatedUser = {
          ...this.$store.state.user,
          user_email: userDetails.email,
          user_name: userDetails.name,
          picture: userDetails.picture,
        };
        this.updateUserDetails({ user: updatedUser });
        this.showToast({ message: "Logged In", color: "s" });
        this.$router.push({ name: ROUTER_URL.publicParentDashboard.name });
      } else {
        // Access denied for non-parent
        localStorage.removeItem("token");
        this.showToast({
          message: "Access Denied: Only parents can log in",
          color: "e",
        });
      }
    },

    googleFailureHandler(err) {
      console.error("Google sign-in failure:", err);
      this.showToast({ message: "Google sign-in failed", color: "e" });
    },

    // ==========  RESET MODAL / FORGOT PASSWORD  ==========
    clearModal() {
      this.signUpPageFlag = false;
      this.modalActionText.title = "Welcome back, Parent";
      this.modalActionText.actionSub = "Dont have an account?  ";
      this.modalActionText.actionBtn = "SignUp";

      // Reset form data
      this.signUp = { loading: false, name: "", email: "", password: "" };
      this.login = { loading: false, email: "", password: "" };
      this.errors.signUp = {};
      this.errors.login = {};
    },

    toggleForm() {
      this.signUpPageFlag = !this.signUpPageFlag;
      if (this.signUpPageFlag) {
        this.modalActionText.title = "Parent Sign up";
        this.modalActionText.actionSub = "Already have an account? ";
        this.modalActionText.actionBtn = "Login";
      } else {
        this.modalActionText.title = "Welcome back, Parent";
        this.modalActionText.actionSub = "Dont have an account? ";
        this.modalActionText.actionBtn = "Signup";
      }
    },

    toggleForgotPasswordModal() {
      this.show = false;
      this.toggleResetPasswordModal({ show: true });
    },
  },
  mounted() {
    // Use the correct handler name
    window.addEventListener("message", this.handleGoogleLoginMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleGoogleLoginMessage);
  },
};
</script>

<style scoped>
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: auto;
}

/* Modal Container */
.modal-container {
  background: white;
  max-width: 500px;
  width: 90%;
  border-radius: 12px;
  overflow: hidden;
  padding: 1.5rem;
  border-bottom: solid 20px #2c1963;
  
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Header */
.modal-header {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}


.modal-logo {
  width: clamp(130px, 50%, 400px); 
  height: auto;
  display: block;
  margin: auto;
  object-fit: contain;
}
.modal-header h2 {
  font-size: clamp(1rem, 2.5vw, 2rem); /* fluid font size */
  margin: 0;
  background-color: #32148d;

}

/* Close Button in top-right corner */
.close-btn {
  position: relative;     /* remove from normal flow */
  font-size: 2rem;      /* icon size */
  background: transparent;
  border: none;
  cursor: pointer;
}

/* Form Styles */
.modal-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}
.modal-form input {
  padding: 0.6rem;
  border: 1px solid #ccc;
  border-radius: 6px;
}

/* Buttons */
.action-btn {
  background-color: #cc0000;
  color: white;
  padding: 0.7rem;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
}

.google-btn {
  background-color: #4285f4;
  color: white;
  padding: 0.6rem;
  margin-top: 0.5rem;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  cursor: pointer;
}

/* Forgot Password Link */
.forgot-password {
  text-align: right;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* Footer Toggle */
.modal-footer {
  margin-top: 1rem;
  text-align: center;
}
.link-btn {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

/* Validation Errors */
.error {
  color: red;
  font-size: 0.8rem;
  margin-top: 2px;
  display: block;
  padding: 3px;
  border-radius: 3px;

}
</style>
